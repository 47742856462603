// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {sHXb5LMtI: {hover: true}};

const cycleOrder = ["sHXb5LMtI", "xVf5F8dsX"];

const variantClassNames = {sHXb5LMtI: "framer-v-1xhpdqx", xVf5F8dsX: "framer-v-6aqli1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "sHXb5LMtI", "Variant 2": "xVf5F8dsX"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; projectName?: string; sonaDesignWork?: {src: string; srcSet?: string}; link?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "sHXb5LMtI", projectName: qSJSxnFuh = "Choice AI Visual Editor", sonaDesignWork: e42zcJw4P = {src: new URL("assets/512/Ra1oVKmm99FJOkGL4fNFM1qjq8.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/Ra1oVKmm99FJOkGL4fNFM1qjq8.jpg", import.meta.url).href} 512w, ${new URL("assets/Ra1oVKmm99FJOkGL4fNFM1qjq8.jpg", import.meta.url).href} 750w`}, link: e_ColBsLI, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "sHXb5LMtI", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-0i8Nl", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<Link href={e_ColBsLI} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx("framer-1xhpdqx", className)} framer-1v71rt8`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"sHXb5LMtI"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"sHXb5LMtI-hover": {"data-framer-name": undefined}, xVf5F8dsX: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fit", intrinsicHeight: 297, intrinsicWidth: 366, pixelHeight: 297, pixelWidth: 366, ...toResponsiveImage_194x2gw(e42zcJw4P)}} className={"framer-ph5nwc"} data-framer-name={"image 21"} layoutDependency={layoutDependency} layoutId={"dD6iHysRQ"} style={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.33)", filter: "grayscale(1)", WebkitFilter: "grayscale(1)"}} transition={transition} variants={{"sHXb5LMtI-hover": {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}, xVf5F8dsX: {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.h3 style={{"--font-selector": "SW50ZXItQm9sZA==", "--framer-font-family": "\"Inter-Bold\", \"Inter\", sans-serif", "--framer-font-weight": "700", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-a0htzi)"}}>Choice AI Visual Editor</motion.h3></React.Fragment>} className={"framer-1192f0z"} data-framer-name={"Shaking things up to democratize design and make it available for all!"} fonts={["Inter-Bold"]} layoutDependency={layoutDependency} layoutId={"gEVpyeO3D"} style={{"--extracted-a0htzi": "rgb(14, 194, 203)", "--framer-paragraph-spacing": "0px"}} text={qSJSxnFuh} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-0i8Nl [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0i8Nl * { box-sizing: border-box; }", ".framer-0i8Nl .framer-1v71rt8 { display: block; }", ".framer-0i8Nl .framer-1xhpdqx { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 10px 10px 10px 10px; position: relative; text-decoration: none; width: 340px; }", ".framer-0i8Nl .framer-ph5nwc { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 320px); overflow: hidden; position: relative; width: 100%; will-change: transform; }", ".framer-0i8Nl .framer-1192f0z { flex: none; height: auto; overflow: hidden; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", ".framer-0i8Nl .framer-v-1xhpdqx .framer-1xhpdqx { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-0i8Nl .framer-1xhpdqx { gap: 0px; } .framer-0i8Nl .framer-1xhpdqx > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-0i8Nl .framer-1xhpdqx > :first-child { margin-top: 0px; } .framer-0i8Nl .framer-1xhpdqx > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 369
 * @framerIntrinsicWidth 340
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"xVf5F8dsX":{"layout":["fixed","auto"]},"xAJNlVtee":{"layout":["fixed","auto"]}}}
 * @framerVariables {"qSJSxnFuh":"projectName","e42zcJw4P":"sonaDesignWork","e_ColBsLI":"link"}
 */
const FramerodqR6D6vu: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerodqR6D6vu;

FramerodqR6D6vu.displayName = "sona-project";

FramerodqR6D6vu.defaultProps = {height: 369, width: 340};

addPropertyControls(FramerodqR6D6vu, {variant: {options: ["sHXb5LMtI", "xVf5F8dsX"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, qSJSxnFuh: {defaultValue: "Choice AI Visual Editor", displayTextArea: false, title: "Project Name", type: ControlType.String}, e42zcJw4P: {__defaultAssetReference: "data:framer/asset-reference,Ra1oVKmm99FJOkGL4fNFM1qjq8.jpg?originalFilename=choice-ai-app-square.jpg&preferredSize=auto", title: "Sona Design Work", type: ControlType.ResponsiveImage}, e_ColBsLI: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerodqR6D6vu, [])